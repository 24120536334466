var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offers-list-item"},[_c('page-title',{attrs:{"has-anchor-icon":false}}),(_vm.state.advert)?_c('a-card',[_c('div',{staticClass:"flex flex-col lg:flex-row lg:gap-5"},[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('text-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'name',
              'prefix': 'advert_',
              'validation': 'required',
            },
          }}}),_c('editor-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'rules',
              'prefix': 'advert_',
              'validation': 'required',
              'counterMax': 2000
            },
          }}})],1),_c('div',{staticClass:"w-full lg:w-1/2"},[_c('post-shop-fields-wrapper',{attrs:{"product-id-model":_vm.state.advert.product_id,"cash-register-id-model":_vm.state.advert.cash_register_id,"prefix":"ads_"},on:{"update:productIdModel":function($event){return _vm.$set(_vm.state.advert, "product_id", $event)},"update:product-id-model":function($event){return _vm.$set(_vm.state.advert, "product_id", $event)},"update:cashRegisterIdModel":function($event){return _vm.$set(_vm.state.advert, "cash_register_id", $event)},"update:cash-register-id-model":function($event){return _vm.$set(_vm.state.advert, "cash_register_id", $event)}}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'targets',
              'validation': 'required',
              'options': _vm.$store.getters.boardTargetOptions(),
              'multiple': true,
              'prefix': 'advert_',
              'translationParams': [ _vm.$store.state.boardsState.activeBoard.title ]
            },
          }}})],1)]),_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'styleId',
          'options': _vm.styles,
          'prefix': 'advert_',
          'clearable': false,
        }
      }},on:{"option:selecting":_vm.onSelectedStyleChange}}),(_vm.selectedStyle && _vm.styleId !== null)?_c('accordion',{key:_vm.styleId,staticClass:"mb-5",attrs:{"open-title":_vm.selectedStyle.label,"close-title":_vm.selectedStyle.label}},[_c('post-styles-setup',{attrs:{"board":_vm.$store.getters.activeBoard.board,"show-delayed-actions":false,"settings-structure":_vm.adStyleStructure},model:{value:(_vm.state.advert.style),callback:function ($$v) {_vm.$set(_vm.state.advert, "style", $$v)},expression:"state.advert.style"}})],1):_vm._e(),_c('div',[_c('config-field',{attrs:{"title":_vm.$t('field_advert_plan_title'),"mini-help-message":_vm.$t('field_advert_plan_mini_help_message'),"required":""}},[_c('slots-list',{staticClass:"my-3 py-1",attrs:{"slots":_vm.state.advert.plan,"ads-only":""},on:{"on-add-slot":function($event){return _vm.state.advert.plan.push($event)},"on-edit-slot":_vm.onEditSlot,"on-remove-slot":_vm.onRemoveSlot}})],1),_c('div',{staticClass:"flex flex-col lg:flex-row lg:items-end lg:gap-5"},[(_vm.state.advert.expire !== undefined)?_c('time-unit-input',{staticClass:"flex-1",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'expire',
              'prefix': _vm.state.advert.expire > 172800 ? 'advert_remove_warn_' : 'advert_'
            },
          }}}):_vm._e(),(_vm.state.advert.top !== undefined)?_c('time-unit-input',{staticClass:"flex-1",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'top',
              'prefix': 'advert_'
            },
          }}}):_vm._e(),(_vm.state.advert !== undefined)?_c('time-unit-input',{staticClass:"flex-1",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'pin',
              'prefix': 'advert_'
            },
          }}}):_vm._e()],1),_c('div',{staticClass:"flex flex-col lg:flex-row lg:items-end lg:gap-5"},[(_vm.state.advert.moderation_timeout !== undefined)?_c('time-unit-input',{staticClass:"flex-1",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'moderation_timeout',
              'prefix': 'advert_'
            },
          }}}):_vm._e(),(_vm.state.advert.pay_timeout !== undefined)?_c('time-unit-input',{staticClass:"flex-1",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.state.advert,
              'key': 'pay_timeout',
              'prefix': 'advert_'
            },
          }}}):_vm._e()],1),(_vm.state.advert.replace_message && _vm.state.advert.expire > 172800 && _vm.state.advert.replace_message !== undefined)?_c('message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.messageEditorToPlainSetter,
          'args': {
            'model': _vm.state.advert,
            'key': 'replace_message',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.activeBoard.board
            },
            'hasMedia': true,
            'base-api-url': 'api.presscode.info/cabinet',
            'allDocument': false,
            'settingsStructure': {
              'disable_link_preview': false,
              'disable_notify': false,
              'pin': false,
              'remove_previous': false,
              'protect_content': false,
            }
          },
        }}}):_vm._e()],1),_c('div',{staticClass:"footer flex justify-end gap-2 mt-5"},[_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.$router.push({name: 'OffersList'})}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('save-channel-config',{attrs:{"disabled":_vm.saveConfigButtonDisabled,"on-click":_vm.onSaveClick}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }